<template>
    <div>
        <div class="approval_search_container">
            <vs-row>
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
                    <div class="enrollment_count">
                        <p class="enrollment_text">Placement Drive</p>
                        <p class="enrollment_text">{{ count_webinars }}</p>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-w="12" vs-offset="0.1" class="mt-4">
                    <vs-row class="my-6">
                        <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
                            <p class="filter-heading">Filters</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="webinar">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                                    Webinar
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <!-- <treeselect v-model="selected_webinar" valueFormat="object" :options="webinars" /> -->
                                    <v-select class="webinarSelect" v-model="selected_webinar" :options="webinars"></v-select>
                                     <!-- <v-select v-model="selected_webinar" :options="webinars">
                                        <template slot="option" slot-scope="option">
                                            <vx-tooltip style="position: inherit;  z-index: 100000" :text="option.label">{{ option.label }}</vx-tooltip>
                                        </template>
                                     </v-select> -->
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="team">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                                    Team
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect :disabled="disabled" :multiple="true" v-model="init_selected_team" placeholder="Select..."
                                        :options="treeTeam" :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="citys">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                                    City
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity"
                                        :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="gmFilter">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    GM
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedGM" :multiple="true" :options="treeDataGM"
                                        :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="spoc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                                    SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect :multiple="true" v-model="initSelectedSpoc" :options="treeDataSpoc"
                                        :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>

                    </vs-row>

                    <vs-row class="my-6">
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-offset="0.9" vs-w="2"
                            class="enrollment-tree-drop none_padding" id="enrolled_spoc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                                    Enrolled By SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect :multiple="true" v-model="initEnrolledSpoc" :options="treeDataSpoc"
                                        :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="level_drop">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="selected_level" :options="treeLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="macc_level_drop"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    CM Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="macc_level_drop"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    USP Levels
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedMaccLevels" :multiple="true"
                                        :options="treeDataMaccLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="attended">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    Placement Drive Status
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initAttended" :options="treeDataAttended" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="m_level_drop"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    CM Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"
                            class="whatsapp-tree-drop none_padding" id="m_level_drop"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    USP Levels
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedMaccLevels" :multiple="true"
                                        :options="treeDataMaccLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                    </vs-row>

                    <vs-row class="my-6">
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-offset="1" vs-w="2"
                            class="whatsapp-tree-drop none_padding mt-3" id="call_status">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                                    Call Status
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initCallStatus" :options="treeDataCallStatus" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center"  vs-w="2.8"
                            class="mt-2">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Registration month and year</label>
                                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'"
                                    class="macc_date_picker" :format="customFormatter" v-model="registration_date">
                                </datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.8" class="mt-2">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Enrollment month and year</label>
                                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'"
                                    class="macc_date_picker" :format="customFormatter" v-model="enrollment_date">
                                </datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7"
                            style="margin-top: 8px;">
                            <div>
                                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" class="mt-2">
                            <vs-button
                            color="#0044BA"
                            style="margin-left: 2%"
                            type="filled"
                            @click="generateReport()"
                            v-if="login_spoc_id == 132 || login_spoc_id == 937 || login_spoc_id == 39"
                            >Generate Report</vs-button>
                        </vs-col>
                    </vs-row>

                    <vs-row class="mt-6 mb-2">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="12">
                            <div class="aplproval_search_criteria">
                                <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                                <vs-row vs-w="12" class="mt-2">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1"
                                        vs-w="10.7">
                                        <div style="display: inline-block; width: 100%">
                                            <span>
                                                <vs-chip v-if="remove_webinar != ''"
                                                    @click="removechip(remove_webinar, 'webinar')" class="criteria_chip"
                                                    closable color="#f1ad78">
                                                    <b>{{ remove_webinar }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_teams" :key="chip">
                                                <vs-chip @click="removechip(chip, 'team')" class="criteria_chip" closable
                                                    color="#caecc5">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in cityData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'city')" color="#EAE5FA">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selectedGMData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'gm')" color="#FFC2C2">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selectedSpocData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'spoc')" color="#BCEBFA">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selectedEnrolledSpocData" :key="generateKey(chip)">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'enrolled spoc')" color="#ffcccc">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-if="selectedLevels != null">
                                                <vs-chip class="criteria_chip" closable
                                                    @click="removechip(selectedLevels.toString(), 'Level')"
                                                    style="color:#000000; background: #99eee3">
                                                    <b v-if="selectedLevels.toString() == 'u_level'">USP Level</b>
                                                    <b v-else>CM Level</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_levels" :key="generateKey(chip)">
                                                <vs-chip v-if="chip !== 'divider' && selectedLevels.toString() == 'm_level'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'level')" color="#edebf3">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_macc_levels" :key="generateKey(chip)">
                                                <vs-chip v-if="chip !== 'divider' && selectedLevels.toString() == 'u_level'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'macc')" color="#edebf3">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_macc_levels" :key="generateKey(chip)">
                                                <vs-chip v-if="chip !== 'divider' && selectedLevels.toString() == 'm_level'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'macc')" color="#c0fdc6">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_levels" :key="generateKey(chip)">
                                                <vs-chip v-if="chip !== 'divider' && selectedLevels.toString() == 'u_level'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'level')" color="#c0fdc6">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-if="selected_attended != null">
                                                <vs-chip class="criteria_chip" closable
                                                    @click="removechip(selected_attended.toString(), 'attended')"
                                                    style="color:#000000; background: #efab77">
                                                    <b v-if="selected_attended.toString() == 'poll_yes'">Poll Response : Yes</b>
                                                    <b v-else-if="selected_attended.toString() == 'attendees'">Attendees</b>
                                                    <b v-else-if="selected_attended.toString() == 'registrants'">Registrants</b>
                                                </vs-chip>
                                            </span>
                                            <span v-if="selected_call_status != null">
                                                <vs-chip class="criteria_chip" closable
                                                    @click="removechip(selected_call_status.toString(), 'callStatus')"
                                                    style="color:#000000; background: #f5e1ce">
                                                    <b v-if="selected_call_status.toString() == 'called'">Called</b>
                                                    <b v-else-if="selected_call_status.toString() == 'not_called'">Not Called</b>
                                                </vs-chip>
                                            </span>
                                        </div>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                                        <vs-button color="#0044ba" icon="search" @click="searchWebinar()"
                                            size="small"></vs-button>
                                        &nbsp;
                                        <vs-button color="#0044ba" icon="refresh" size="small"
                                            @click="refreshData"></vs-button>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </div>

        <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
            <vs-row vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                GM Stats
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center">
                            <div class="fixTableHead">
                                <table class="rwd-table" id="levels-stats">
                                    <thead>
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM' || selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'SR,CM' || selected_teams.length == 0">
                                            <th>City</th>
                                            <th>GM</th>
                                            <th v-for="mlevel in cm_levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'SR'">
                                            <th>City</th>
                                            <th>GM</th>
                                            <th v-for="mlevel in sr_levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                        <!-- <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                                            <th>City Classification</th>
                                            <th>GM</th>
                                            <th v-for="mlevel in levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr> -->
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'u_level' && selected_teams.length != 0">
                                            <th>City</th>
                                            <th>GM</th>
                                            <th v-for="ulevel in u_levels_list" :key="ulevel">
                                                {{ ulevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM' || selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'SR,CM' || selected_teams.length == 0">
                                        <tr v-for="item in gm_city_stats" :key="generateKey(item.city_classification)">
                                            <td v-if="item.city_classification != null">{{ item.city_classification }}</td>
                                            <td v-else>No City</td>
                                            <td>{{ item.gm_name }}</td>
                                            <td id="stats_total" v-for="t_col in cm_levels"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, t_col, 'gm_city_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, 'total', 'gm_city_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'SR'">
                                        <tr v-for="item in gm_city_stats" :key="generateKey(item.city_classification)">
                                            <td v-if="item.city_classification != null">{{ item.city_classification }}</td>
                                            <td v-else>No City</td>
                                            <td>{{ item.gm_name }}</td>
                                            <td id="stats_total" v-for="t_col in sr_levels"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, t_col, 'gm_city_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, 'total', 'gm_city_stats')">
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                                        <tr v-for="item in gm_city_stats" :key="item">
                                            <td v-if="item.city_classification != null">{{ item.city_classification }}</td>
                                            <td v-else>No City Classification</td>
                                            <td>{{ item.gm_name }}</td>
                                            <td id="stats_total" v-for="t_col in levels" :key="t_col"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, t_col, 'gm_city_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, 'total', 'gm_city_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody> -->
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'u_level' && selected_teams.length != 0">
                                        <tr v-for="item in gm_city_stats" :key="generateKey(item.city_calssification)">
                                            <td v-if="item.city_classification != null">{{ item.city_classification }}</td>
                                            <td v-else>No City</td>
                                            <td>{{ item.gm_name }}</td>
                                            <td id="stats_total" v-for="t_col in u_levels_list"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, t_col, 'gm_city_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.city_classification, item.gm_id, 'total', 'gm_city_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td v-if="gm_city_stats.length != 0">Total</td>
                                            <td v-if="gm_city_stats.length != 0"></td>
                                            <td v-for="(val, key) in total_of_gm_stats" :key="generateKey(key)">{{ val }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
            <vs-row vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                Registration Stats
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center">
                            <div class="fixTableHead">
                                <table class="rwd-table" id="levels-stats">
                                    <thead>
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM' || selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'SR,CM' || selected_teams.length == 0">
                                            <th>Source</th>
                                            <th v-for="mlevel in cm_levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'SR'">
                                            <th>Source</th>
                                            <th v-for="mlevel in sr_levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                        <!-- <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                                            <th>Source</th>
                                            <th v-for="mlevel in levels" :key="mlevel">
                                                {{ mlevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr> -->
                                        <tr
                                            v-if="selectedLevels != null && selectedLevels.toString() == 'u_level' && selected_teams.length != 0">
                                            <th>Source</th>
                                            <th v-for="ulevel in u_levels_list" :key="ulevel">
                                                {{ ulevel }}
                                            </th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM' || selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'SR,CM' || selected_teams.length == 0">
                                        <tr v-for="item in registration_stats" :key="generateKey(item.city)">
                                            <td v-if="item.source != null">{{ item.source }}</td>
                                            <td v-else>No Source</td>
                                            <td id="stats_total" v-for="t_col in cm_levels"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.source, '', t_col, 'registration_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.source, '', 'total', 'registration_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'SR'">
                                        <tr v-for="item in registration_stats" :key="generateKey(item.city)">
                                            <td v-if="item.source != null">{{ item.source }}</td>
                                            <td v-else>No Source</td>
                                            <td id="stats_total" v-for="t_col in sr_levels"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.source, '', t_col, 'registration_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.source, '', 'total', 'registration_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                    <!-- <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                                        <tr v-for="item in registration_stats" :key="item.city">
                                            <td v-if="item.source != null">{{ item.source }}</td>
                                            <td v-else>No Source</td>
                                            <td id="stats_total" v-for="t_col in levels" :key="t_col"
                                                @click="getTabulatorDeatilsPage(item.source, '', t_col, 'registration_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.source, '', 'total', 'registration_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody> -->
                                    <tbody class="tbl-content"
                                        v-if="selectedLevels != null && selectedLevels.toString() == 'u_level' && selected_teams.length != 0">
                                        <tr v-for="item in registration_stats" :key="item.source">
                                            <td v-if="item.source != null">{{ item.source }}</td>
                                            <td v-else>No Source</td>
                                            <td id="stats_total" v-for="t_col in u_levels_list"
                                                :key="generateKey(item.level_count[t_col])"
                                                @click="getTabulatorDeatilsPage(item.source, '', t_col, 'registration_stats')">
                                                {{ item.level_count[t_col] }}
                                            </td>
                                            <td id="stats_total"
                                                @click="getTabulatorDeatilsPage(item.source, '', 'total', 'registration_stats')">
                                                {{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td v-if="registration_stats.length != 0">Total</td>
                                            <td v-for="(val, key) in total_of_registration_stats" :key="key">{{ val }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <div v-if="tabulator_details.length != 0">
            <PlacementDriveTabulator :source="source" :city_calssification="city_calssification"
                :selected_stats="selected_stats" :table_details="tabulator_details"></PlacementDriveTabulator>
            <vs-row style="margin-top: 3%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
                    <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
                </vs-col>
            </vs-row>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue-tree-halower/dist/halower-tree.min.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import Treeselect from "@riophae/vue-treeselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import PlacementDriveTabulator from "./PlacementDriveTabulator.vue";
import EventBus from "../components/eventbus";
export default {
    components: {
        VTree,
        vSelect,
        DateRangePicker,
        Datepicker,
        VSelectTree,
        Treeselect,
        PlacementDriveTabulator,
    },
    data() {
        return {
            webinars: [],
            selected_webinar: null,
            remove_webinar: "",
            registration_date: null,
            enrollment_date: null,
            cm_levels: ['M1', 'M2', 'M3', 'M3+', 'M3++', 'M4-', 'M4', 'M5', 'M6', 'NE', 'Others'],
            sr_levels: ['M7', 'M7-', 'M7+', 'M7#', 'M8', 'M8+', 'M8-', 'M9', 'M9-', 'M10', 'M7-JJ', 'M7X', 'M7D1', 'M7D2', 'M7-IR', 'M7-INT', 'NE', 'Others'],
            levels: ['M1', 'M2', 'M3', 'M3+', 'M3++', 'M4-', 'M4', 'M5', 'M6', 'M7', 'M7-', 'M7+', 'M7#', 'M8', 'M8+', 'M8-', 'M9', 'M9-', 'M10', 'M7-JJ', 'M7X', 'M7D1', 'M7D2', 'M7-IR', 'M7-INT', 'NE', 'Others'],
            u_levels_list: ['Blank','U0--', 'U0', 'U1', 'U1+', 'U2', 'U3-', 'U3', 'U3+', 'U3++', 'U4', 'U5', 'U6', 'U7-', 'U7','U7R','U7+', 'U8', 'U8+', 'U9-', 'U9', 'U9+', 'U10-', 'U10', 'U11', 'U11+', 'U12', 'NE', 'Others'],
            treeLevels: [
                { id: "m_level", label: "CM Level" },
                { id: "u_level", label: "USP Level" }
            ],
            treeDataAttended: [
                { id: "poll_yes", label: "Poll Response : Yes" },
                { id: "attendees", label: "Attendees" },
                { id: "registrants", label: "Registrants" }
            ],
            treeDataCallStatus: [
                { id: "called", label: "Called" },
                { id: "not_called", label: "Not Called" },
            ],
            initAttended: null,
            initCallStatus: null,
            selected_level: null,
            selectedLevels: null,
            user_with_gms: [],
            gm_spocs_data: [],
            city: "",
            initSelectedCity: [],
            treeDataCity: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            initSelectedSpoc: [],
            initEnrolledSpoc: [],
            treeDataSpoc: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            initSelectedGM: [],
            treeDataGM: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            feedback_options: [
                {
                    id: "excited",
                    label: "I'm excited",
                },
                {
                    id: "okay",
                    label: "It was ok",
                },
                {
                    id: "not_satisfied",
                    label: "Not satisfied",
                },
            ],
            treeDataMaccLevels: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        { id: "Blank", label: "No U Level" },
                        { id: "U0--", label: "U0--" },
                        { id: "U0", label: "U0" },
                        { id: "U1", label: "U1" },
                        { id: "U1+", label: "U1+" },
                        { id: "U2", label: "U2" },
                        { id: "U3-", label: "U3-" },
                        { id: "U3", label: "U3" },
                        { id: "U3+", label: "U3+" },
                        { id: "U3++", label: "U3++"},
                        { id: "U4", label: "U4" },
                        { id: "U5", label: "U5" },
                        { id: "U6", label: "U6" },
                        { id: "U7-", label: "U7-" },
                        { id: "U7", label: "U7" },
                        { id: "U7R", label: "U7R" },
                        { id: "U7+", label: "U7+" },
                        { id: "U8", label: "U8" },
                        { id: "U8+", label: "U8+" },
                        { id: "U9-", label: "U9-" },
                        { id: "U9", label: "U9" },
                        { id: "U9+", label: "U9+" },
                        { id: "U10-", label: "U10-" },
                        { id: "U10", label: "U10" },
                        { id: "U11", label: "U11" },
                        { id: "U11+", label: "U11+" },
                        { id: "U12", label: "U12" },
                    ]
                }
            ],
            treeDataLevels: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        {
                            id: "P1",
                            label: "P1",
                            children: [
                                {
                                    id: "M3",
                                    label: "M3",
                                },
                                {
                                    id: "M3+",
                                    label: "M3+",
                                },
                                {
                                    id: "M3++",
                                    label: "M3++",
                                },
                                {
                                    id: "M5",
                                    label: "M5",
                                },
                                {
                                    id: "M6",
                                    label: "M6",
                                },
                                {
                                    id: "M6 AR",
                                    label: "M6 AR",
                                },
                                {
                                    id: "M6 AR-",
                                    label: "M6 AR-",
                                },
                            ],
                        },
                        {
                            id: "P2",
                            label: "P2",
                            children: [
                                {
                                    id: "M4",
                                    label: "M4",
                                },
                                {
                                    id: "M2",
                                    label: "M2",
                                },
                            ],
                        },
                        {
                            id: "P3",
                            label: "P3",
                            children: [
                                {
                                    id: "M4-",
                                    label: "M4-",
                                },
                                {
                                    id: "M1",
                                    label: "M1",
                                },
                                // {
                                //   id: "L4-",
                                //   label: "L4-",
                                // },
                                // {
                                //   id: "L1",
                                //   label: "L1",
                                // },
                            ],
                        },
                        {
                            id: "ENROLLED",
                            label: "ENROLLED",
                            children: [
                                {
                                    id: "M7",
                                    label: "M7",
                                },
                                {
                                    id: "M7-",
                                    label: "M7-",
                                },
                                {
                                    id: "M7+",
                                    label: "M7+",
                                },
                                {
                                    id: "M7#",
                                    label: "M7#",
                                },
                                {
                                    id: "M7-JJ",
                                    label: "M7-JJ",
                                },
                                {
                                    id: "M7X",
                                    label: "M7X",
                                },
                                {
                                    id: "M7D1",
                                    label: "M7D1",
                                },
                                {
                                    id: "M7D2",
                                    label: "M7D2",
                                },
                                {
                                    id: "M8#1",
                                    label: "M8#1"
                                },
                                {
                                    id: "M8#1-",
                                    label: "M8#1-"
                                },
                                {
                                    id: "M8#1+",
                                    label: "M8#1+"
                                },
                                {
                                    id: "M8#2",
                                    label: "M8#2"
                                },
                                {
                                    id: "M8",
                                    label: "M8"
                                },
                                {
                                    id: "M8-",
                                    label: "M8-",
                                },
                                {
                                    id: "M8+",
                                    label: "M8+"
                                },
                                {
                                    id: "M8+1",
                                    label: "M8+1"
                                },
                                {
                                    id: "M8+2",
                                    label: "M8+2"
                                },
                                {
                                    id: "M8+3",
                                    label: "M8+3"
                                },
                                {
                                    id: "M8+4",
                                    label: "M8+4"
                                },
                                {
                                    id: "M8+5",
                                    label: "M8+5"
                                },
                                {
                                    id: "M8+6",
                                    label: "M8+6"
                                },
                                {
                                    id: "M9",
                                    label: "M9",
                                },
                                {
                                    id: "M9+1",
                                    label: "M9+1",
                                },
                                {
                                    id: "M9+2",
                                    label: "M9+2",
                                },
                                {
                                    id: "M9+3",
                                    label: "M9+3",
                                },
                                {
                                    id: "M9-",
                                    label: "M9-",
                                },
                                {
                                    id: "M10",
                                    label: "M10",
                                },
                                {
                                    id: "M10#",
                                    label: "M10#",
                                },
                                {
                                    id: "M11",
                                    label: "M11",
                                },
                                {
                                    id: "M11#",
                                    label: "M11#",
                                },
                                {
                                    id: "M12",
                                    label: "M12",
                                },
                                {
                                    id: "M7-IR",
                                    label: "M7-IR",
                                },
                                {
                                    id: "M7-INT",
                                    label: "M7-INT",
                                }
                            ]
                        },
                        {
                            id: "Dnd",
                            label: "Dnd",
                            children: [
                                {
                                    id: "N/A",
                                    label: "N/A",
                                },
                                {
                                    id: "DND",
                                    label: "DND",
                                },
                            ],
                        },
                    ],
                },
            ],
            count_webinars: 0,
            cityData: [],
            selectedSpocData: [],
            selectedEnrolledSpocData: [],
            selectedGMData: [],
            login_spoc_id: "",
            initSelectedLevels: [],
            initSelectedMaccLevels: [],
            selected_levels: [],
            selected_macc_levels: [],
            init_selected_team: [],
            disabled: false,
            selected_teams: [],
            selected_attended: null,
            selected_call_status: null,
            gm_ids: [],
            gm_city_stats: [],
            registration_stats: [],
            total_of_gm_stats: {},
            total_of_registration_stats: {},
            treeTeam: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        { id: "CM", label: "CM" },
                        { id: "SR", label: "SR" },
                        // { id: "ExEd", label: "MAcc" },
                    ]
                }
            ],
            loggedin_team: "",
            spoc_ids_data: "",
            enrolled_spoc_ids_data:'',
            ExEd_selected_team: [],
            selected_table: '',
            gmId: '',
            currentTablePage: 1,
            tablelinks: 0,
            tabulator_details: [],
            clicked_row: {},
            city_calssification: '',
            selected_stats: '',
            source: '',
            default_selected_city: [],
            default_selected_gm:[],
            default_selected_spoc:[],
            all_users: [],
            cm_users: [],
            sr_users: [],
            webinar_date: '',
            selected_filters: '',
        };
    },
    watch: {
        selected_webinar(val) {
            console.log("selected_webinar", val);
            if (val == undefined) {
                this.remove_webinar = "";
                this.webinar_date = "";
            }else{
                this.webinar_date = val.webinar_date;
            }
            this.mergeAllSearch()
            // this.getStats(val);
        },
        selected_level: function () {
            this.initSelectedLevels = [];
            this.initSelectedMaccLevels = [];
            this.mergeAllSearch()
            // this.getUsersWithGMs();
        },
        initAttended: function () {
            this.mergeAllSearch()
        },
        initCallStatus: function () {
            this.mergeAllSearch()
        },
        init_selected_team: function (val) {
            console.log('object', val);
            this.gm_city_stats = []
            this.total_of_gm_stats = {}
            this.registration_stats = []
            this.total_of_registration_stats = {}
            this.tabulator_details = []
            this.treeDataSpoc =  [
                {
                id: "All",
                label: "All",
                children: [],
                },
            ],
            this.user_with_gms = [];
            if(this.init_selected_team.includes('CM')){
                this.user_with_gms = this.cm_users;
                this.getDropdownData();
            }
            if(this.init_selected_team.includes('SR')){
                this.user_with_gms = this.sr_users;
                this.getDropdownData();
            }
            if(!this.init_selected_team.includes('SR') && !this.init_selected_team.includes('CM')){
                this.user_with_gms = this.all_users;
                this.getDropdownData();
            }
            this.mergeAllSearch();
        },
        initSelectedCity: function (val) {
            if (val.length != 0) {
                this.getSpocIds()
                this.mergeAllSearch();
            }
            else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM') {
                    this.initSelectedCity = this.default_selected_city
                }
            }
            if(val.length == 0){
                this.cityData = [];
            }
        },
        initSelectedGM: function (val) {
            if (val.length != 0) {
                this.getSpocIds()
                this.mergeAllSearch();
            } else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM') {
                    this.initSelectedGM = this.default_selected_gm
                }
            }
            if(val.length == 0){
                this.selectedGMData = [];
            }
        },
        initSelectedSpoc: function (val) {
            if (val.length != 0) {
                this.getSpocIds()
                this.mergeAllSearch();
            } else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM') {
                    this.initSelectedSpoc = this.default_selected_spoc
                }
            }
            if(val.length == 0){
                this.selectedSpocData = [];
            }
        },
        initEnrolledSpoc: function (val) {
            this.mergeAllSearch();
            console.log('selectedEnrolledSpocData', val);
            this.getEnrolledSpocIds()
        },
        initSelectedLevels: function () {
            this.gm_city_stats = []
            this.total_of_gm_stats = {}
            this.registration_stats = []
            this.total_of_registration_stats = {}
            this.tabulator_details = []
            this.mergeAllSearch();
        },
        initSelectedMaccLevels: function () {
            this.mergeAllSearch();
        },
        currentTablePage(val) {
            console.log(val);
            this.getTabulatorDeatils(this.clicked_row.row, this.gmId, this.clicked_row.val, this.selected_table)
        },
    },
    mounted() {
        EventBus.$on("update-placementdrive", (payload) => {
            this.updatePlacementdrive(payload)
        });
        this.selected_level = 'm_level';
        this.login_spoc_id = localStorage.getItem("spoc_id");
        this.getUsersWithGMs()
        this.userWiseTeam();
        this.fetchWebinars();
    },
    methods: {
        fetchWebinars() {
            this.treeDataTopics = []
            axios
                .get("https://milescrmdev.2x2.ninja/api/getWebinarDetails",
                    {
                        headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                    }
                )
                .then((response) => {
                    console.log("pastWebinar", response.data);
                    const webinars = [];
                    response.data.forEach((w) => {
                        let webinar = w;
                        webinar.title = `${moment
                            .unix(webinar.start_time_unix)
                            .format("DD-MMM-YYYY")} : ${w.topic}`;
                        webinar.webinar_date = moment.unix(webinar.start_time_unix).format("DD-MMM-YYYY")
                        webinars.push(webinar); 
                    });
                    console.log("jfhgjfhgui", webinars);
                    this.webinars = [];
                    webinars.forEach((element) => {
                        // console.log("webinar dashboard", element);
                        let webinar_obj = {
                            id: element.id,
                            label: element.title,
                            webinar_id: element.webinar_id,
                            webinar_date: element.webinar_date
                        };
                        this.webinars.push(webinar_obj);
                    });
                    // this.webinars = webinars;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        generateReport() {
            let url = `${constants.MILES_CM_BACK}startPDReportGeneration?name=GeneratePDReport`;
            axios
                .get(url, {
                    headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("response", response.data);
                    if(response.data.status === 'success'){
                        this.$vs.notify({
                        title: `Success`,
                        text: response.data.message,
                        color: "success",
                        });
                    }else if(response.data.status === 'error'){
                        this.$vs.notify({
                        title: `Error`,
                        text: response.data.message,
                        color: "danger",
                        });
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
        },
        clearDateFilter() {
            this.registration_date = null;
            this.enrollment_date = null;
        },
        customFormatter(date) {
            return moment(date).format("MMM-YYYY");
        },
        userWiseTeam() {
            this.init_selected_team = [];
            this.loggedin_team = localStorage.getItem('team');
            if(this.loggedin_team == 'Admin'){
                this.disabled = false;
            } else {
                this.disabled = true;
            }
            if (this.loggedin_team === 'Admin') {
                this.init_selected_team.push('CM');
            }
            if (this.loggedin_team === 'CM') {
                this.init_selected_team.push('CM');
            } else if (this.loggedin_team === 'SR') {
                this.init_selected_team.push('SR');
            } else if (this.loggedin_team === 'ExEd') {
                this.init_selected_team.push('ExEd');
            } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'CM') {
                this.init_selected_team.push('CM');
            }
            else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") != 'CM') {
                this.init_selected_team.push('SR');
            }
        },
        getUsersWithGMs() {
        let url = `${constants.SERVER_API}getUsersWithGMs`;
        axios
            .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
            })
            .then((response) => {
            let logged_in_user = localStorage.getItem('spoc_id')
            let team = localStorage.getItem('team')
            if (team == 'CM' || team == 'SR' || team == 'ExEd') {
                response.data.forEach(ele => {
                if (logged_in_user == ele.id) {
                    this.user_with_gms = []
                    this.user_with_gms.push(ele);
                    this.getDropdownData();
                }
                });
            } else if (localStorage.getItem('team') == 'GM') {
                this.getGmReportingSpocs(response.data)
            } else {
                this.sr_users = [];
                this.cm_users = [];
                this.all_users = [];
                this.user_with_gms = [];
                for (let i = 0; i < response.data.length; i++) {
                const element = response.data[i];
                    if(element.team == 'CM'){
                    this.cm_users.push(element);
                    }
                    if(element.team == 'SR'){
                    this.sr_users.push(element);
                    } 
                    this.all_users.push(element);
                    
                }
                this.user_with_gms = [];
                if(this.init_selected_team.includes('CM')){
                this.user_with_gms = this.cm_users;
                this.getDropdownData();
                }
                if(this.init_selected_team.includes('SR')){
                this.user_with_gms = this.sr_users;
                this.getDropdownData();
                }
                if(!this.init_selected_team.includes('SR') && !this.init_selected_team.includes('CM')){
                this.user_with_gms = this.all_users;
                this.getDropdownData();
                }
            }
            })
            .catch((error) => {
            this.handleError(error);
            }).finally(()=>{
            this.getSpocIds();
            })
        },
        getGmReportingSpocs(res) {
            let gm_id = localStorage.getItem("spoc_id");
            let ids = [];
            let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    for (let i = 0; i < response.data.spocs.length; i++) {
                        const element = response.data.spocs[i];
                        ids.push(element.id);
                    }
                    this.gm_ids = ids
                    this.user_with_gms = []
                    this.gm_ids.forEach(gm => {
                        res.forEach(ele => {
                            if (ele.id == gm) {
                                this.user_with_gms.push(ele);
                            }
                        });
                    });
                    this.getDropdownData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getDropdownData() {
        this.initSelectedCity = [];
        this.initSelectedGM = [];
        this.initSelectedSpoc = [];
        this.treeDataCity = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ]
        let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            if(city_options.includes(element)){
                this.treeDataCity[0].children.push(obj);
            }
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataCity[0].children.forEach((city) => {
            if (element.city === city.label) {
                let cc_set = new Set(city.children.map((item) => item.label));
                // if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                // let cc_obj = {
                //     id: element.city + "_" + element.city_classification,
                //     label: element.city_classification
                // }
                // city.children.push(cc_obj);
                // let team = localStorage.getItem('team')
                // if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                //     this.initSelectedCity.push(cc_obj)
                // }
                // this.default_selected_city = this.initSelectedCity;
                // }
                if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                        let city_classifaication = element.city_classification
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let cc_obj = {
                            id: element.city + "_" + element.city_classification,
                            label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }else{
                        if(city_classifaication.substring(0,1) != 'X'){
                            let cc_obj = {
                                id: element.city + "_" + element.city_classification,
                                label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }
                        }
                 }
            }
            })
        });
        this.treeDataGM = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ],
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            this.treeDataGM[0].children.push(obj);
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataGM[0].children.forEach((city) => {
            if (element.city === city.label) {
                if (element.gm !== "No GM") {
                let gm_set = new Set(city.children.map((item) => item.label));
                // if (!gm_set.has(element.gm)) {
                //     let gm_obj = {
                //     id: element.city + "_" + element.gm,
                //     label: element.gm
                //     }
                //     city.children.push(gm_obj);
                //     let team = localStorage.getItem('team')
                //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                //     this.initSelectedGM.push(gm_obj)
                //     }
                //     this.default_selected_gm = this.initSelectedGM
                // }
                if (!gm_set.has(element.gm)) {
                        let gm_data = element.gm
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let gm_obj = {
                            id: element.city + "_" + element.gm,
                            label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }else{
                        if(gm_data.substring(0,1) != 'X'){
                            let gm_obj = {
                                id:element.city + "_" + element.gm,
                                label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }
                        }
                 }
                }
            }
            })
        });
        this.treeDataSpoc = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ],
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            this.treeDataSpoc[0].children.push(obj);
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataSpoc[0].children.forEach((city) => {
            if (element.city === city.label) {
                let gm_set = new Set(city.children.map((item) => item.label));
                // if (!gm_set.has(element.last_name)) {
                // let spoc_obj = {
                //     id: element.city + "_" + element.first_name + " " + element.last_name,
                //     label: element.first_name + " " + element.last_name
                // }
                // city.children.push(spoc_obj);
                // let team = localStorage.getItem('team')
                //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                //     this.initSelectedSpoc.push(spoc_obj)
                //     }
                //     this.default_selected_spoc = this.initSelectedSpoc
                // }
                if (!gm_set.has(element.last_name)) {
                        let name = element.first_name
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let spoc_obj = {
                            id: element.city + "_" + element.first_name + " " + element.last_name,
                            label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                this.default_selected_spoc = this.initSelectedSpoc

                        }else{
                        if(name.substring(0,1) != 'X'){
                            let spoc_obj = {
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                this.default_selected_spoc = this.initSelectedSpoc

                        }
                        }
                    }
            }
            })
        });
        },
        refreshData() {
            this.count_webinars = 0;
            this.tabulator_details = []
            this.clicked_row = {};
            this.selected_webinar = null;
            this.registration_date = null;
            this.enrollment_date = null;
            this.selected_level = 'm_level';
            this.treeDataCity = [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
                this.treeDataGM = [
                    {
                        id: "All",
                        label: "All",
                        children: [],
                    },
                ],
                this.treeDataSpoc = [
                    {
                        id: "All",
                        label: "All",
                        children: [],
                    },
                ],
                this.initAttended = null;
                this.initCallStatus = null;
            this.selected_attended = null;
            this.selected_call_status = null;
            this.initSelectedCity = [];
            this.cityData = [];
            this.initSelectedGM = [];
            this.selectedGMData = [];
            this.initSelectedSpoc = [];
            this.initEnrolledSpoc = [];
            this.selectedSpocData = [];
            this.selectedEnrolledSpocData = [];
            this.initSelectedMaccLevel = [];
            this.init_selected_team = [];
            this.ExEd_selected_team = [];
            this.selected_teams = [];
            this.selected_levels = [];
            this.selected_macc_levels = [];
            this.initSelectedMaccLevels = [];
            this.initSelectedLevels = [];
            this.clearDateFilter();
            this.userWiseTeam();
            this.getUsersWithGMs();
        },
        mergeAllSearch() {
            if (this.selected_webinar != null && this.selected_webinar != undefined) {
                this.remove_webinar = "";
                this.remove_webinar = this.selected_webinar.label;
            }
            this.selectedLevels = null;
            this.selectedLevels = this.selected_level
            this.selected_teams = []
            this.init_selected_team.forEach((sort) => {
                if (sort === "All") {
                    this.treeTeam[0].children.forEach((child) => {
                        this.selected_teams.push(child.label);
                    });
                } else {
                    this.selected_teams.push(sort);
                }
            });
            this.selected_attended = null;
            this.selected_attended = this.initAttended;
            this.selected_call_status = null;
            this.selected_call_status = this.initCallStatus;
            this.cityData = [];
            this.initSelectedCity.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataCity[0].children.forEach((child) => {
                        this.cityData.push(child.label);
                    });
                } else {
                    this.cityData.push(sort.label);
                }
            });
            this.selectedGMData = [];
            this.initSelectedGM.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataGM[0].children.forEach((child) => {
                        this.selectedGMData.push(child.label);
                    });
                } else {
                    if (!this.selectedGMData.includes(sort.label)) {
                        this.selectedGMData.push(sort.label);
                    }
                }
            });
            this.selectedSpocData = [];
            this.initSelectedSpoc.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataSpoc[0].children.forEach((child) => {
                        this.selectedSpocData.push(child.label);
                    });
                } else {
                    if (!this.selectedSpocData.includes(sort.label)) {
                        this.selectedSpocData.push(sort.label);
                    }
                }
            });

            this.selectedEnrolledSpocData = [];
            this.initEnrolledSpoc.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataSpoc[0].children.forEach((child) => {
                        this.selectedEnrolledSpocData.push(child.label);
                    });
                } else {
                    if (!this.selectedEnrolledSpocData.includes(sort.label)) {
                        this.selectedEnrolledSpocData.push(sort.label);
                    }
                }
            });

            this.selected_levels = [];
            this.initSelectedLevels.forEach((sort) => {
                if (sort === "All") {
                    this.treeDataLevels[0].children[0].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                    this.treeDataLevels[0].children[1].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                    this.treeDataLevels[0].children[2].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                    this.treeDataLevels[0].children[3].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                    this.treeDataLevels[0].children[4].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else if (sort === "P1") {
                    this.treeDataLevels[0].children[0].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else if (sort === "P2") {
                    this.treeDataLevels[0].children[1].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else if (sort === "P3") {
                    this.treeDataLevels[0].children[2].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else if (sort === "ENROLLED") {
                    this.treeDataLevels[0].children[3].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else if (sort === "Dnd") {
                    this.treeDataLevels[0].children[4].children.forEach((child) => {
                        this.selected_levels.push(child.label);
                    });
                } else {
                    this.selected_levels.push(sort);
                }
            });

            this.selected_macc_levels = [];
            this.initSelectedMaccLevels.forEach((sort) => {
                if (sort === "All") {
                    this.treeDataMaccLevels[0].children.forEach((child) => {
                        this.selected_macc_levels.push(child.label);
                    });
                } else {
                    this.selected_macc_levels.push(sort);
                }
            });

        },
        removechip(chip, name) {
            let team = localStorage.getItem('team')
            if (name == "webinar") {
                this.selected_webinar = null;
                this.remove_webinar = "";
                console.log("fdgfdhfh", this.selected_webinar);
            }
            if (name == "team") {
                if(team != 'CM' && team != 'SR' && team != 'GM'){
                    this.selected_teams = [];
                    if (this.init_selected_team.indexOf(chip) === -1) {
                        this.init_selected_team = [];
                        this.treeTeam[0].children.forEach((child) => {
                            if (child.label !== chip) {
                                this.init_selected_team.push(child.label);
                                this.selected_teams.push(child.label);
                            }
                        });
                    } else {
                        this.init_selected_team.splice(this.init_selected_team.indexOf(chip), 1);
                        this.selected_teams.splice(this.selected_teams.indexOf(chip), 1);
                    }
                }
            }
            if (name == 'attended') {
                this.selected_attended = null;
                this.initAttended = null;
            }
            if (name == 'callStatus') {
                this.selected_call_status = null;
                this.initCallStatus = null;
            }
            if (name == "city") {
                if(team != 'CM' && team != 'SR' && team != 'GM'){
                    this.cityData.splice(this.cityData.indexOf(chip), 1);
                    let index = this.initSelectedCity.findIndex(x => x.label === chip);
                    this.initSelectedCity.splice(index, 1);
                }
            }
            if (name == "gm") {
                if(team != 'CM' && team != 'SR' && team != 'GM'){
                    this.selectedGMData.splice(this.selectedGMData.indexOf(chip), 1);
                    let index = this.initSelectedGM.findIndex(x => x.label === chip);
                    this.initSelectedGM.splice(index, 1);
                }
            }

            if (name == "spoc") {
                if(team != 'CM' && team != 'SR' && team != 'GM'){
                    this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
                    let index = this.initSelectedSpoc.findIndex(x => x.label === chip);
                    this.initSelectedSpoc.splice(index, 1);
                }
            }

            if (name == "enrolled spoc") {
                this.selectedEnrolledSpocData.splice(this.selectedEnrolledSpocData.indexOf(chip), 1);
                let index = this.initEnrolledSpoc.findIndex(x => x.label === chip);
                this.initEnrolledSpoc.splice(index, 1);
            }

            if (name == "level") {
                this.selected_levels.splice(this.selected_levels.indexOf(chip), 1);
                if (this.initSelectedLevels.indexOf(chip) !== -1) {
                    this.initSelectedLevels.splice(
                        this.initSelectedLevels.indexOf(chip),
                        1
                    );
                } else {
                    let childProperty = "";
                    this.treeDataLevels[0].children.forEach((child) => {
                        if ("children" in child) {
                            child.children.forEach((chi) => {
                                if (chi.label === chip) {
                                    childProperty = child.label;
                                }
                            });
                        }
                    });
                    this.initSelectedLevels.splice(
                        this.initSelectedLevels.indexOf(childProperty),
                        1
                    );
                    this.treeDataLevels[0].children.forEach((child) => {
                        if (child.label === childProperty) {
                            if ("children" in child) {
                                child.children.forEach((chi) => {
                                    if (chi.label !== chip) {
                                        this.initSelectedLevels.push(chi.label);
                                    }
                                });
                            }
                        }
                    });
                }
            }

            if (name == 'macc') {
                this.selected_macc_levels = [];
                if (this.initSelectedMaccLevels.indexOf(chip) === -1) {
                    this.initSelectedMaccLevels = [];
                    this.treeDataMaccLevels[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedMaccLevels.push(child.label);
                            this.selected_macc_levels.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedMaccLevels.splice(this.initSelectedMaccLevels.indexOf(chip), 1);
                    this.selected_macc_levels.splice(this.selected_macc_levels.indexOf(chip), 1);
                }
            }
        },
        getEnrolledSpocIds() {
            let city = [];
            let uniqe_city = [];
            let uniqe_spoc = [];
            if (this.initEnrolledSpoc.length != 0) {
                this.initEnrolledSpoc.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(data => {
                            uniqe_spoc.push(data.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        uniqe_spoc.push(item.label)
                    }
                });

                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }
            let enrolled_spoc_ids = [];
            this.user_with_gms.forEach(raw => {
                if (this.initEnrolledSpoc.length != 0) {
                    uniqe_city.forEach(city => {
                        uniqe_spoc.forEach(spoc => {
                            if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                                enrolled_spoc_ids.push(raw.id);
                            }
                        })
                    })
                }
            });
            enrolled_spoc_ids = [...new Set(enrolled_spoc_ids.map((data) => data))];
            console.log("enrolled spoc Ids", enrolled_spoc_ids.join())
            this.enrolled_spoc_ids_data = enrolled_spoc_ids.join()

        },
        getSpocIds() {
            let city = [];
            let cc = [];
            let uniqe_city = [];
            let uniqe_gm = [];
            let uniqe_spoc = [];
            if (this.initSelectedCity.length != 0) {
                this.initSelectedCity.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(CC => {
                            cc.push(CC.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        cc.push(item.label)
                    }
                });

                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];

            }
            if (this.initSelectedGM.length != 0) {
                this.initSelectedGM.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(data => {
                            uniqe_gm.push(data.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        uniqe_gm.push(item.label)
                    }
                });

                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }
            if (this.initSelectedSpoc.length != 0) {
                this.initSelectedSpoc.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(data => {
                            uniqe_spoc.push(data.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        uniqe_spoc.push(item.label)
                    }
                });

                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }

            let spoc_ids = [];
            this.user_with_gms.forEach(raw => {
                if (localStorage.getItem('team') == 'GM') {
                    if (this.initSelectedSpoc.length != 0) {
                        uniqe_city.forEach(city => {
                            uniqe_spoc.forEach(spoc => {
                                if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                                    spoc_ids.push(raw.id);
                                }
                            })
                        })
                    }
                } else {
                    if (this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0) {
                        uniqe_city.forEach(city => {
                            cc.forEach(cc => {
                                if (raw.city == city && raw.city_classification == cc) {
                                    spoc_ids.push(raw.id);
                                }
                            })
                        })
                    } if (this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0) {
                        uniqe_city.forEach(city => {
                            uniqe_gm.forEach(gm => {
                                if (raw.city == city && raw.gm == gm) {
                                    spoc_ids.push(raw.id);
                                }
                            })
                        })
                    }
                    // uniqe_city.length != 0 && uniqe_gm.length != 0 &&
                    if (this.initSelectedSpoc.length != 0) {
                        uniqe_city.forEach(city => {
                            uniqe_spoc.forEach(spoc => {
                                if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                                    spoc_ids.push(raw.id);
                                }
                            })
                        })
                    }
                    if (this.initEnrolledSpoc.length != 0) {
                        uniqe_city.forEach(city => {
                            uniqe_spoc.forEach(spoc => {
                                if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                                    spoc_ids.push(raw.id);
                                }
                            })
                        })
                    }
                }


            });
            if (this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0 && this.init_selected_team != 0) {
                this.treeDataSpoc[0].children.forEach(child => {
                    child.children.forEach(spoc => {
                        this.user_with_gms.forEach(raw => {
                            if (raw.first_name + " " + raw.last_name == spoc.label) {
                                spoc_ids.push(raw.id);
                            }
                        });
                    });
                });
            }

            spoc_ids = [...new Set(spoc_ids.map((data) => data))];
            console.log("spoc Ids", spoc_ids.join())
            this.spoc_ids_data = spoc_ids.join()
        },
        searchWebinar() {
            this.getSpocIds();
            console.log("spoc_ids_dataaa22", this.spoc_ids_data);
            if (this.selected_webinar == null || this.selected_webinar == undefined) {
                this.$vs.notify({
                    title: "Please select webinar",
                    color: "danger",
                });
            } else {
                this.getStats(this.selected_webinar);
            }
        },
        getStats(val) {
            this.tabulator_details = [];
            this.$vs.loading();
            let obj = {
                webinar_date: this.webinar_date,
                spoc_ids: this.spoc_ids_data,
                enrolled_by: this.enrolled_spoc_ids_data,
                level_type: this.selectedLevels.toString(),
                webinar_id: val.webinar_id,
                selected_team: this.selected_teams.toString()
            }
            if (this.selectedLevels != null && this.selectedLevels.toString() == 'm_level' && this.selected_levels.length != 0) {
                obj['levels'] = this.selected_levels.toString()
            } else if (this.selectedLevels != null && this.selectedLevels.toString() == 'u_level' && this.selected_macc_levels.length != 0) {
                obj['levels'] = this.selected_macc_levels.toString()
            }
            if (this.selectedLevels != null && this.selectedLevels.toString() == 'u_level' && this.selected_levels.length != 0) {
                obj['m_levels'] = this.selected_levels.toString()
            } else if (this.selectedLevels != null && this.selectedLevels.toString() == 'm_level' && this.selected_macc_levels.length != 0) {
                obj['u_levels'] = this.selected_macc_levels.toString()
            }
            if (this.selected_attended != null) {
                obj['placement_drive_status'] = this.selected_attended.toString();
            }
            if (this.selected_call_status != null) {
                obj['call_status'] = this.selected_call_status.toString();
            }
            if (this.registration_date != null) {
                obj['month'] = moment(this.registration_date).format("MM");
                obj['year'] = moment(this.registration_date).format("YYYY")
            }
            if (this.enrollment_date != null) {
                obj['enrollment_month'] = moment(this.enrollment_date).format("MM");
                obj['enrollment_year'] = moment(this.enrollment_date).format("YYYY")
            }
            console.log("seleted filters", obj, val);
            let url = `${constants.MILES_CRM_DEV}placementDriveGMStats`;
            axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                .then((response) => {
                    console.log("response1", response.data);
                    this.gm_city_stats = response.data.gm_city_stats;
                    this.getGmStatsTotals(this.gm_city_stats);
                    axios.get(`${constants.MILES_CRM_DEV}placementDriveSourceStats`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                        .then((response) => {
                            this.$vs.loading.close(); 
                            console.log("response2", response.data);
                            this.registration_stats = response.data.registration_stats;
                            this.getRegistrationStatsTotals(this.registration_stats)
                        })
                    // this.gm_city_stats = response.data.gm_city_stats.sort((a, b) => a.city.localeCompare(b.city));
                })
                .catch((error) => {
                    this.handleError(error)
                    this.$vs.loading.close();
                });
        },
        getGmStatsTotals(data) {
            this.total_of_gm_stats = {}
            data.forEach(element => {
                if (!this.total_of_gm_stats.hasOwnProperty('M1') && element.level_count['M1'] != undefined) {
                    this.total_of_gm_stats['M1'] = element.level_count['M1']
                }
                else if (element.level_count['M1'] != undefined) {
                    this.total_of_gm_stats['M1'] += element.level_count['M1']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M2') && element.level_count['M2'] != undefined) {
                    this.total_of_gm_stats['M2'] = element.level_count['M2']
                }
                else if (element.level_count['M2'] != undefined) {
                    this.total_of_gm_stats['M2'] += element.level_count['M2']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M3') && element.level_count['M3'] != undefined) {
                    this.total_of_gm_stats['M3'] = element.level_count['M3']
                }
                else if (element.level_count['M3'] != undefined) {
                    this.total_of_gm_stats['M3'] += element.level_count['M3']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M3+') && element.level_count['M3+'] != undefined) {
                    this.total_of_gm_stats['M3+'] = element.level_count['M3+']
                }
                else if (element.level_count['M3+'] != undefined) {
                    this.total_of_gm_stats['M3+'] += element.level_count['M3+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M3++') && element.level_count['M3++'] != undefined) {
                    this.total_of_gm_stats['M3++'] = element.level_count['M3++']
                }
                else if (element.level_count['M3++'] != undefined) {
                    this.total_of_gm_stats['M3++'] += element.level_count['M3++']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M4-') && element.level_count['M4-'] != undefined) {
                    this.total_of_gm_stats['M4-'] = element.level_count['M4-']
                }
                else if (element.level_count['M4-'] != undefined) {
                    this.total_of_gm_stats['M4-'] += element.level_count['M4-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M4') && element.level_count['M4'] != undefined) {
                    this.total_of_gm_stats['M4'] = element.level_count['M4']
                }
                else if (element.level_count['M4'] != undefined) {
                    this.total_of_gm_stats['M4'] += element.level_count['M4']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M5') && element.level_count['M5'] != undefined) {
                    this.total_of_gm_stats['M5'] = element.level_count['M5']
                }
                else if (element.level_count['M5'] != undefined) {
                    this.total_of_gm_stats['M5'] += element.level_count['M5']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M6') && element.level_count['M6'] != undefined) {
                    this.total_of_gm_stats['M6'] = element.level_count['M6']
                }
                else if (element.level_count['M6'] != undefined) {
                    this.total_of_gm_stats['M6'] += element.level_count['M6']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7') && element.level_count['M7'] != undefined) {
                    this.total_of_gm_stats['M7'] = element.level_count['M7']
                }
                else if (element.level_count['M7'] != undefined) {
                    this.total_of_gm_stats['M7'] += element.level_count['M7']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7-') && element.level_count['M7-'] != undefined) {
                    this.total_of_gm_stats['M7-'] = element.level_count['M7-']
                }
                else if (element.level_count['M7-'] != undefined) {
                    this.total_of_gm_stats['M7-'] += element.level_count['M7-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7+') && element.level_count['M7+'] != undefined) {
                    this.total_of_gm_stats['M7+'] = element.level_count['M7+']
                }
                else if (element.level_count['M7+'] != undefined) {
                    this.total_of_gm_stats['M7+'] += element.level_count['M7+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7#') && element.level_count['M7#'] != undefined) {
                    this.total_of_gm_stats['M7#'] = element.level_count['M7#']
                }
                else if (element.level_count['M7#'] != undefined) {
                    this.total_of_gm_stats['M7#'] += element.level_count['M7#']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M8') && element.level_count['M8'] != undefined) {
                    this.total_of_gm_stats['M8'] = element.level_count['M8']
                }
                else if (element.level_count['M8'] != undefined) {
                    this.total_of_gm_stats['M8'] += element.level_count['M8']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M8+') && element.level_count['M8+'] != undefined) {
                    this.total_of_gm_stats['M8+'] = element.level_count['M8+']
                }
                else if (element.level_count['M8+'] != undefined) {
                    this.total_of_gm_stats['M8+'] += element.level_count['M8+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M8-') && element.level_count['M8-'] != undefined) {
                    this.total_of_gm_stats['M8-'] = element.level_count['M8-']
                }
                else if (element.level_count['M8-'] != undefined) {
                    this.total_of_gm_stats['M8-'] += element.level_count['M8-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M9') && element.level_count['M9'] != undefined) {
                    this.total_of_gm_stats['M9'] = element.level_count['M9']
                }
                else if (element.level_count['M9'] != undefined) {
                    this.total_of_gm_stats['M9'] += element.level_count['M9']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M9-') && element.level_count['M9-'] != undefined) {
                    this.total_of_gm_stats['M9-'] = element.level_count['M9-']
                }
                else if (element.level_count['M9-'] != undefined) {
                    this.total_of_gm_stats['M9-'] += element.level_count['M9-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M10') && element.level_count['M10'] != undefined) {
                    this.total_of_gm_stats['M10'] = element.level_count['M10']
                }
                else if (element.level_count['M10'] != undefined) {
                    this.total_of_gm_stats['M10'] += element.level_count['M10']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7-JJ') && element.level_count['M7-JJ'] != undefined) {
                    this.total_of_gm_stats['M7-JJ'] = element.level_count['M7-JJ']
                }
                else if (element.level_count['M7-JJ'] != undefined) {
                    this.total_of_gm_stats['M7-JJ'] += element.level_count['M7-JJ']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7X') && element.level_count['M7X'] != undefined) {
                    this.total_of_gm_stats['M7X'] = element.level_count['M7X']
                }
                else if (element.level_count['M7X'] != undefined) {
                    this.total_of_gm_stats['M7X'] += element.level_count['M7X']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7D1') && element.level_count['M7D1'] != undefined) {
                    this.total_of_gm_stats['M7D1'] = element.level_count['M7D1']
                }
                else if (element.level_count['M7D1'] != undefined) {
                    this.total_of_gm_stats['M7D1'] += element.level_count['M7D1']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7D2') && element.level_count['M7D2'] != undefined) {
                    this.total_of_gm_stats['M7D2'] = element.level_count['M7D2']
                }
                else if (element.level_count['M7D2'] != undefined) {
                    this.total_of_gm_stats['M7D2'] += element.level_count['M7D2']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7-IR') && element.level_count['M7-IR'] != undefined) {
                    this.total_of_gm_stats['M7-IR'] = element.level_count['M7-IR']
                }
                else if (element.level_count['M7-IR'] != undefined) {
                    this.total_of_gm_stats['M7-IR'] += element.level_count['M7-IR']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('M7-INT') && element.level_count['M7-INT'] != undefined) {
                    this.total_of_gm_stats['M7-INT'] = element.level_count['M7-INT']
                }
                else if (element.level_count['M7-INT'] != undefined) {
                    this.total_of_gm_stats['M7-INT'] += element.level_count['M7-INT']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('Blank') && element.level_count['Blank'] != undefined) {
                    this.total_of_gm_stats['Blank'] = element.level_count['Blank']
                }
                else if (element.level_count['Blank'] != undefined) {
                    this.total_of_gm_stats['Blank'] += element.level_count['Blank']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U0--') && element.level_count['U0--'] != undefined) {
                    this.total_of_gm_stats['U0--'] = element.level_count['U0--']
                }
                else if (element.level_count['U0--'] != undefined) {
                    this.total_of_gm_stats['U0--'] += element.level_count['U0--']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U0') && element.level_count['U0'] != undefined) {
                    this.total_of_gm_stats['U0'] = element.level_count['U0']
                }
                else if (element.level_count['U0'] != undefined) {
                    this.total_of_gm_stats['U0'] += element.level_count['U0']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U1') && element.level_count['U1'] != undefined) {
                    this.total_of_gm_stats['U1'] = element.level_count['U1']
                }
                else if (element.level_count['U1'] != undefined) {
                    this.total_of_gm_stats['U1'] += element.level_count['U1']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U1+') && element.level_count['U1+'] != undefined) {
                    this.total_of_gm_stats['U1+'] = element.level_count['U1+']
                }
                else if (element.level_count['U1+'] != undefined) {
                    this.total_of_gm_stats['U1+'] += element.level_count['U1+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U2') && element.level_count['U2'] != undefined) {
                    this.total_of_gm_stats['U2'] = element.level_count['U2']
                }
                else if (element.level_count['U2'] != undefined) {
                    this.total_of_gm_stats['U2'] += element.level_count['U2']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U3-') && element.level_count['U3-'] != undefined) {
                    this.total_of_gm_stats['U3-'] = element.level_count['U3-']
                }
                else if (element.level_count['U3-'] != undefined) {
                    this.total_of_gm_stats['U3-'] += element.level_count['U3-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U3') && element.level_count['U3'] != undefined) {
                    this.total_of_gm_stats['U3'] = element.level_count['U3']
                }
                else if (element.level_count['U3'] != undefined) {
                    this.total_of_gm_stats['U3'] += element.level_count['U3']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U3+') && element.level_count['U3+'] != undefined) {
                    this.total_of_gm_stats['U3+'] = element.level_count['U3+']
                }
                else if (element.level_count['U3+'] != undefined) {
                    this.total_of_gm_stats['U3+'] += element.level_count['U3+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U3++') && element.level_count['U3++'] != undefined) {
                    this.total_of_gm_stats['U3++'] = element.level_count['U3++']
                }
                else if (element.level_count['U3++'] != undefined) {
                    this.total_of_gm_stats['U3++'] += element.level_count['U3++']
                }
                // if (!this.total_of_gm_stats.hasOwnProperty('U4-') && element.level_count['U4-'] != undefined) {
                //     this.total_of_gm_stats['U4-'] = element.level_count['U4-']
                // }
                // else if (element.level_count['U4-'] != undefined) {
                //     this.total_of_gm_stats['U4-'] += element.level_count['U4-']
                // }
                if (!this.total_of_gm_stats.hasOwnProperty('U4') && element.level_count['U4'] != undefined) {
                    this.total_of_gm_stats['U4'] = element.level_count['U4']
                }
                else if (element.level_count['U4'] != undefined) {
                    this.total_of_gm_stats['U4'] += element.level_count['U4']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U5') && element.level_count['U5'] != undefined) {
                    this.total_of_gm_stats['U5'] = element.level_count['U5']
                }
                else if (element.level_count['U5'] != undefined) {
                    this.total_of_gm_stats['U5'] += element.level_count['U5']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U6') && element.level_count['U6'] != undefined) {
                    this.total_of_gm_stats['U6'] = element.level_count['U6']
                }
                else if (element.level_count['U6'] != undefined) {
                    this.total_of_gm_stats['U6'] += element.level_count['U6']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U7-') && element.level_count['U7-'] != undefined) {
                    this.total_of_gm_stats['U7-'] = element.level_count['U7-']
                }
                else if (element.level_count['U7-'] != undefined) {
                    this.total_of_gm_stats['U7-'] += element.level_count['U7-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U7') && element.level_count['U7'] != undefined) {
                    this.total_of_gm_stats['U7'] = element.level_count['U7']
                }
                else if (element.level_count['U7'] != undefined) {
                    this.total_of_gm_stats['U7'] += element.level_count['U7']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U7R') && element.level_count['U7R'] != undefined) {
                    this.total_of_gm_stats['U7R'] = element.level_count['U7R']
                }
                else if (element.level_count['U7R'] != undefined) {
                    this.total_of_gm_stats['U7R'] += element.level_count['U7R']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U7+') && element.level_count['U7+'] != undefined) {
                    this.total_of_gm_stats['U7+'] = element.level_count['U7+']
                }
                else if (element.level_count['U7+'] != undefined) {
                    this.total_of_gm_stats['U7+'] += element.level_count['U7+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U8') && element.level_count['U8'] != undefined) {
                    this.total_of_gm_stats['U8'] = element.level_count['U8']
                }
                else if (element.level_count['U8'] != undefined) {
                    this.total_of_gm_stats['U8'] += element.level_count['U8']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U8+') && element.level_count['U8+'] != undefined) {
                    this.total_of_gm_stats['U8+'] = element.level_count['U8+']
                }
                else if (element.level_count['U8+'] != undefined) {
                    this.total_of_gm_stats['U8+'] += element.level_count['U8+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U9-') && element.level_count['U9-'] != undefined) {
                    this.total_of_gm_stats['U9-'] = element.level_count['U9-']
                }
                else if (element.level_count['U9-'] != undefined) {
                    this.total_of_gm_stats['U9-'] += element.level_count['U9-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U9') && element.level_count['U9'] != undefined) {
                    this.total_of_gm_stats['U9'] = element.level_count['U9']
                }
                else if (element.level_count['U9'] != undefined) {
                    this.total_of_gm_stats['U9'] += element.level_count['U9']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U9+') && element.level_count['U9+'] != undefined) {
                    this.total_of_gm_stats['U9+'] = element.level_count['U9+']
                }
                else if (element.level_count['U9+'] != undefined) {
                    this.total_of_gm_stats['U9+'] += element.level_count['U9+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U10-') && element.level_count['U10-'] != undefined) {
                    this.total_of_gm_stats['U10-'] = element.level_count['U10-']
                }
                else if (element.level_count['U10-'] != undefined) {
                    this.total_of_gm_stats['U10-'] += element.level_count['U10-']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U10') && element.level_count['U10'] != undefined) {
                    this.total_of_gm_stats['U10'] = element.level_count['U10']
                }
                else if (element.level_count['U10'] != undefined) {
                    this.total_of_gm_stats['U10'] += element.level_count['U10']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U11') && element.level_count['U11'] != undefined) {
                    this.total_of_gm_stats['U11'] = element.level_count['U11']
                }
                else if (element.level_count['U11'] != undefined) {
                    this.total_of_gm_stats['U11'] += element.level_count['U11']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U11+') && element.level_count['U11+'] != undefined) {
                    this.total_of_gm_stats['U11+'] = element.level_count['U11+']
                }
                else if (element.level_count['U11+'] != undefined) {
                    this.total_of_gm_stats['U11+'] += element.level_count['U11+']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('U12') && element.level_count['U12'] != undefined) {
                    this.total_of_gm_stats['U12'] = element.level_count['U12']
                }
                else if (element.level_count['U12'] != undefined) {
                    this.total_of_gm_stats['U12'] += element.level_count['U12']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('NE') && element.level_count['NE'] != undefined) {
                    this.total_of_gm_stats['NE'] = element.level_count['NE']
                }
                else if (element.level_count['NE'] != undefined) {
                    this.total_of_gm_stats['NE'] += element.level_count['NE']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('Others') && element.level_count['Others'] != undefined) {
                    this.total_of_gm_stats['Others'] = element.level_count['Others']
                }
                else if (element.level_count['Others'] != undefined) {
                    this.total_of_gm_stats['Others'] += element.level_count['Others']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('total') && element['total'] != undefined) {
                    this.total_of_gm_stats['total'] = element['total']
                }
                else if (element['total'] != undefined) {
                    this.total_of_gm_stats['total'] += element['total']
                }

            });
            console.log("totals", this.total_of_gm_stats);
            this.count_webinars = this.total_of_gm_stats.total;
        },
        getRegistrationStatsTotals(data) {
            this.total_of_registration_stats = {}
            data.forEach(element => {
                if (!this.total_of_registration_stats.hasOwnProperty('M1') && element.level_count['M1'] != undefined) {
                    this.total_of_registration_stats['M1'] = element.level_count['M1']
                }
                else if (element.level_count['M1'] != undefined) {
                    this.total_of_registration_stats['M1'] += element.level_count['M1']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M2') && element.level_count['M2'] != undefined) {
                    this.total_of_registration_stats['M2'] = element.level_count['M2']
                }
                else if (element.level_count['M2'] != undefined) {
                    this.total_of_registration_stats['M2'] += element.level_count['M2']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M3') && element.level_count['M3'] != undefined) {
                    this.total_of_registration_stats['M3'] = element.level_count['M3']
                }
                else if (element.level_count['M3'] != undefined) {
                    this.total_of_registration_stats['M3'] += element.level_count['M3']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M3+') && element.level_count['M3+'] != undefined) {
                    this.total_of_registration_stats['M3+'] = element.level_count['M3+']
                }
                else if (element.level_count['M3+'] != undefined) {
                    this.total_of_registration_stats['M3+'] += element.level_count['M3+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M3++') && element.level_count['M3++'] != undefined) {
                    this.total_of_registration_stats['M3++'] = element.level_count['M3++']
                }
                else if (element.level_count['M3++'] != undefined) {
                    this.total_of_registration_stats['M3++'] += element.level_count['M3++']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M4-') && element.level_count['M4-'] != undefined) {
                    this.total_of_registration_stats['M4-'] = element.level_count['M4-']
                }
                else if (element.level_count['M4-'] != undefined) {
                    this.total_of_registration_stats['M4-'] += element.level_count['M4-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M4') && element.level_count['M4'] != undefined) {
                    this.total_of_registration_stats['M4'] = element.level_count['M4']
                }
                else if (element.level_count['M4'] != undefined) {
                    this.total_of_registration_stats['M4'] += element.level_count['M4']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M5') && element.level_count['M5'] != undefined) {
                    this.total_of_registration_stats['M5'] = element.level_count['M5']
                }
                else if (element.level_count['M5'] != undefined) {
                    this.total_of_registration_stats['M5'] += element.level_count['M5']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M6') && element.level_count['M6'] != undefined) {
                    this.total_of_registration_stats['M6'] = element.level_count['M6']
                }
                else if (element.level_count['M6'] != undefined) {
                    this.total_of_registration_stats['M6'] += element.level_count['M6']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7') && element.level_count['M7'] != undefined) {
                    this.total_of_registration_stats['M7'] = element.level_count['M7']
                }
                else if (element.level_count['M7'] != undefined) {
                    this.total_of_registration_stats['M7'] += element.level_count['M7']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7-') && element.level_count['M7-'] != undefined) {
                    this.total_of_registration_stats['M7-'] = element.level_count['M7-']
                }
                else if (element.level_count['M7-'] != undefined) {
                    this.total_of_registration_stats['M7-'] += element.level_count['M7-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7+') && element.level_count['M7+'] != undefined) {
                    this.total_of_registration_stats['M7+'] = element.level_count['M7+']
                }
                else if (element.level_count['M7+'] != undefined) {
                    this.total_of_registration_stats['M7+'] += element.level_count['M7+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7#') && element.level_count['M7#'] != undefined) {
                    this.total_of_registration_stats['M7#'] = element.level_count['M7#']
                }
                else if (element.level_count['M7#'] != undefined) {
                    this.total_of_registration_stats['M7#'] += element.level_count['M7#']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M8') && element.level_count['M8'] != undefined) {
                    this.total_of_registration_stats['M8'] = element.level_count['M8']
                }
                else if (element.level_count['M8'] != undefined) {
                    this.total_of_registration_stats['M8'] += element.level_count['M8']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M8+') && element.level_count['M8+'] != undefined) {
                    this.total_of_registration_stats['M8+'] = element.level_count['M8+']
                }
                else if (element.level_count['M8+'] != undefined) {
                    this.total_of_registration_stats['M8+'] += element.level_count['M8+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M8-') && element.level_count['M8-'] != undefined) {
                    this.total_of_registration_stats['M8-'] = element.level_count['M8-']
                }
                else if (element.level_count['M8-'] != undefined) {
                    this.total_of_registration_stats['M8-'] += element.level_count['M8-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M9') && element.level_count['M9'] != undefined) {
                    this.total_of_registration_stats['M9'] = element.level_count['M9']
                }
                else if (element.level_count['M9'] != undefined) {
                    this.total_of_registration_stats['M9'] += element.level_count['M9']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M9-') && element.level_count['M9-'] != undefined) {
                    this.total_of_registration_stats['M9-'] = element.level_count['M9-']
                }
                else if (element.level_count['M9-'] != undefined) {
                    this.total_of_registration_stats['M9-'] += element.level_count['M9-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M10') && element.level_count['M10'] != undefined) {
                    this.total_of_registration_stats['M10'] = element.level_count['M10']
                }
                else if (element.level_count['M10'] != undefined) {
                    this.total_of_registration_stats['M10'] += element.level_count['M10']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7-JJ') && element.level_count['M7-JJ'] != undefined) {
                    this.total_of_registration_stats['M7-JJ'] = element.level_count['M7-JJ']
                }
                else if (element.level_count['M7-JJ'] != undefined) {
                    this.total_of_registration_stats['M7-JJ'] += element.level_count['M7-JJ']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7X') && element.level_count['M7X'] != undefined) {
                    this.total_of_registration_stats['M7X'] = element.level_count['M7X']
                }
                else if (element.level_count['M7X'] != undefined) {
                    this.total_of_registration_stats['M7X'] += element.level_count['M7X']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7D1') && element.level_count['M7D1'] != undefined) {
                    this.total_of_registration_stats['M7D1'] = element.level_count['M7D1']
                }
                else if (element.level_count['M7D1'] != undefined) {
                    this.total_of_registration_stats['M7D1'] += element.level_count['M7D1']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7D2') && element.level_count['M7D2'] != undefined) {
                    this.total_of_registration_stats['M7D2'] = element.level_count['M7D2']
                }
                else if (element.level_count['M7D2'] != undefined) {
                    this.total_of_registration_stats['M7D2'] += element.level_count['M7D2']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7-IR') && element.level_count['M7-IR'] != undefined) {
                    this.total_of_registration_stats['M7-IR'] = element.level_count['M7-IR']
                }
                else if (element.level_count['M7-IR'] != undefined) {
                    this.total_of_registration_stats['M7-IR'] += element.level_count['M7-IR']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('M7-INT') && element.level_count['M7-INT'] != undefined) {
                    this.total_of_registration_stats['M7-INT'] = element.level_count['M7-INT']
                }
                else if (element.level_count['M7-INT'] != undefined) {
                    this.total_of_registration_stats['M7-INT'] += element.level_count['M7-INT']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('Blank') && element.level_count['Blank'] != undefined) {
                    this.total_of_registration_stats['Blank'] = element.level_count['Blank']
                }
                else if (element.level_count['Blank'] != undefined) {
                    this.total_of_registration_stats['Blank'] += element.level_count['Blank']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U0--') && element.level_count['U0--'] != undefined) {
                    this.total_of_registration_stats['U0--'] = element.level_count['U0--']
                }
                else if (element.level_count['U0--'] != undefined) {
                    this.total_of_registration_stats['U0--'] += element.level_count['U0--']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U0') && element.level_count['U0'] != undefined) {
                    this.total_of_registration_stats['U0'] = element.level_count['U0']
                }
                else if (element.level_count['U0'] != undefined) {
                    this.total_of_registration_stats['U0'] += element.level_count['U0']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U1') && element.level_count['U1'] != undefined) {
                    this.total_of_registration_stats['U1'] = element.level_count['U1']
                }
                else if (element.level_count['U1'] != undefined) {
                    this.total_of_registration_stats['U1'] += element.level_count['U1']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U1+') && element.level_count['U1+'] != undefined) {
                    this.total_of_registration_stats['U1+'] = element.level_count['U1+']
                }
                else if (element.level_count['U1+'] != undefined) {
                    this.total_of_registration_stats['U1+'] += element.level_count['U1+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U2') && element.level_count['U2'] != undefined) {
                    this.total_of_registration_stats['U2'] = element.level_count['U2']
                }
                else if (element.level_count['U2'] != undefined) {
                    this.total_of_registration_stats['U2'] += element.level_count['U2']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U3-') && element.level_count['U3-'] != undefined) {
                    this.total_of_registration_stats['U3-'] = element.level_count['U3-']
                }
                else if (element.level_count['U3-'] != undefined) {
                    this.total_of_registration_stats['U3-'] += element.level_count['U3-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U3') && element.level_count['U3'] != undefined) {
                    this.total_of_registration_stats['U3'] = element.level_count['U3']
                }
                else if (element.level_count['U3'] != undefined) {
                    this.total_of_registration_stats['U3'] += element.level_count['U3']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U3+') && element.level_count['U3+'] != undefined) {
                    this.total_of_registration_stats['U3+'] = element.level_count['U3+']
                }
                else if (element.level_count['U3+'] != undefined) {
                    this.total_of_registration_stats['U3+'] += element.level_count['U3+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U3++') && element.level_count['U3++'] != undefined) {
                    this.total_of_registration_stats['U3++'] = element.level_count['U3++']
                }
                else if (element.level_count['U3++'] != undefined) {
                    this.total_of_registration_stats['U3++'] += element.level_count['U3++']
                }
                // if (!this.total_of_registration_stats.hasOwnProperty('U4-') && element.level_count['U4-'] != undefined) {
                //     this.total_of_registration_stats['U4-'] = element.level_count['U4-']
                // }
                // else if (element.level_count['U4-'] != undefined) {
                //     this.total_of_registration_stats['U4-'] += element.level_count['U4-']
                // }
                if (!this.total_of_registration_stats.hasOwnProperty('U4') && element.level_count['U4'] != undefined) {
                    this.total_of_registration_stats['U4'] = element.level_count['U4']
                }
                else if (element.level_count['U4'] != undefined) {
                    this.total_of_registration_stats['U4'] += element.level_count['U4']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U5') && element.level_count['U5'] != undefined) {
                    this.total_of_registration_stats['U5'] = element.level_count['U5']
                }
                else if (element.level_count['U5'] != undefined) {
                    this.total_of_registration_stats['U5'] += element.level_count['U5']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U6') && element.level_count['U6'] != undefined) {
                    this.total_of_registration_stats['U6'] = element.level_count['U6']
                }
                else if (element.level_count['U6'] != undefined) {
                    this.total_of_registration_stats['U6'] += element.level_count['U6']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U7-') && element.level_count['U7-'] != undefined) {
                    this.total_of_registration_stats['U7-'] = element.level_count['U7-']
                }
                else if (element.level_count['U7-'] != undefined) {
                    this.total_of_registration_stats['U7-'] += element.level_count['U7-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U7') && element.level_count['U7'] != undefined) {
                    this.total_of_registration_stats['U7'] = element.level_count['U7']
                }
                else if (element.level_count['U7'] != undefined) {
                    this.total_of_registration_stats['U7'] += element.level_count['U7']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U7R') && element.level_count['U7R'] != undefined) {
                    this.total_of_registration_stats['U7R'] = element.level_count['U7R']
                }
                else if (element.level_count['U7R'] != undefined) {
                    this.total_of_registration_stats['U7R'] += element.level_count['U7R']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U7+') && element.level_count['U7+'] != undefined) {
                    this.total_of_registration_stats['U7+'] = element.level_count['U7+']
                }
                else if (element.level_count['U7+'] != undefined) {
                    this.total_of_registration_stats['U7+'] += element.level_count['U7+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U8') && element.level_count['U8'] != undefined) {
                    this.total_of_registration_stats['U8'] = element.level_count['U8']
                }
                else if (element.level_count['U8'] != undefined) {
                    this.total_of_registration_stats['U8'] += element.level_count['U8']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U8+') && element.level_count['U8+'] != undefined) {
                    this.total_of_registration_stats['U8+'] = element.level_count['U8+']
                }
                else if (element.level_count['U8+'] != undefined) {
                    this.total_of_registration_stats['U8+'] += element.level_count['U8+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U9-') && element.level_count['U9-'] != undefined) {
                    this.total_of_registration_stats['U9-'] = element.level_count['U9-']
                }
                else if (element.level_count['U9-'] != undefined) {
                    this.total_of_registration_stats['U9-'] += element.level_count['U9-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U9') && element.level_count['U9'] != undefined) {
                    this.total_of_registration_stats['U9'] = element.level_count['U9']
                }
                else if (element.level_count['U9'] != undefined) {
                    this.total_of_registration_stats['U9'] += element.level_count['U9']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U9+') && element.level_count['U9+'] != undefined) {
                    this.total_of_registration_stats['U9+'] = element.level_count['U9+']
                }
                else if (element.level_count['U9+'] != undefined) {
                    this.total_of_registration_stats['U9+'] += element.level_count['U9+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U10-') && element.level_count['U10-'] != undefined) {
                    this.total_of_registration_stats['U10-'] = element.level_count['U10-']
                }
                else if (element.level_count['U10-'] != undefined) {
                    this.total_of_registration_stats['U10-'] += element.level_count['U10-']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U10') && element.level_count['U10'] != undefined) {
                    this.total_of_registration_stats['U10'] = element.level_count['U10']
                }
                else if (element.level_count['U10'] != undefined) {
                    this.total_of_registration_stats['U10'] += element.level_count['U10']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U11') && element.level_count['U11'] != undefined) {
                    this.total_of_registration_stats['U11'] = element.level_count['U11']
                }
                else if (element.level_count['U11'] != undefined) {
                    this.total_of_registration_stats['U11'] += element.level_count['U11']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U11+') && element.level_count['U11+'] != undefined) {
                    this.total_of_registration_stats['U11+'] = element.level_count['U11+']
                }
                else if (element.level_count['U11+'] != undefined) {
                    this.total_of_registration_stats['U11+'] += element.level_count['U11+']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('U12') && element.level_count['U12'] != undefined) {
                    this.total_of_registration_stats['U12'] = element.level_count['U12']
                }
                else if (element.level_count['U12'] != undefined) {
                    this.total_of_registration_stats['U12'] += element.level_count['U12']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('NE') && element.level_count['NE'] != undefined) {
                    this.total_of_registration_stats['NE'] = element.level_count['NE']
                }
                else if (element.level_count['NE'] != undefined) {
                    this.total_of_registration_stats['NE'] += element.level_count['NE']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('Others') && element.level_count['Others'] != undefined) {
                    this.total_of_registration_stats['Others'] = element.level_count['Others']
                }
                else if (element.level_count['Others'] != undefined) {
                    this.total_of_registration_stats['Others'] += element.level_count['Others']
                }
                if (!this.total_of_registration_stats.hasOwnProperty('total') && element['total'] != undefined) {
                    this.total_of_registration_stats['total'] = element['total']
                }
                else if (element['total'] != undefined) {
                    this.total_of_registration_stats['total'] += element['total']
                }

            });
            console.log("totals", this.total_of_registration_stats);
        },
        getTabulatorDeatilsPage(row, gm_id, val, stats) {
            this.gmId = gm_id;
            this.selected_table = stats;
            this.currentTablePage = 1
            this.getTabulatorDeatils(row, gm_id, val, stats)
        },
        getTabulatorDeatils(row, gm_id, val, stats) {
            console.log('ll', gm_id);
            let selected_values = {
                "row" : row,
                "gm_id" : gm_id,
                "val" : val,
                "stats" : stats
            }
            this.selected_filters = selected_values
            this.tabulator_details = []
            this.$vs.loading();
            this.clicked_row['row'] = row
            this.clicked_row['val'] = val
            let obj = {
                webinar_date: this.webinar_date,
                spoc_ids: this.spoc_ids_data,
                enrolled_by: this.enrolled_spoc_ids_data,
                webinar_id: this.selected_webinar.webinar_id,
                level_type: this.selectedLevels.toString(),
                city_classification: '',
                source: '',
                selected_level: val,
                selected_team: this.selected_teams.toString()
            }
            if (this.selectedLevels != null && this.selectedLevels.toString() == 'm_level' && this.selected_levels.length != 0) {
                obj['levels'] = this.selected_levels.toString()
            } else if (this.selectedLevels != null && this.selectedLevels.toString() == 'u_level' && this.selected_macc_levels.length != 0) {
                obj['levels'] = this.selected_macc_levels.toString()
            }
            if (this.selectedLevels != null && this.selectedLevels.toString() == 'u_level' && this.selected_levels.length != 0) {
                obj['m_levels'] = this.selected_levels.toString()
            } else if (this.selectedLevels != null && this.selectedLevels.toString() == 'm_level' && this.selected_macc_levels.length != 0) {
                obj['u_levels'] = this.selected_macc_levels.toString()
            }
            if (gm_id != null) {
                obj['gm_id'] = gm_id;
            } else {
                obj['gm_id'] = 'no_gm';
            }
            // if (this.selected_levels.length != 0) {
            //     obj['levels'] = this.selected_levels.toString()
            // } else if (this.selected_macc_levels.length != 0) {
            //     obj['levels'] = this.selected_macc_levels.toString()
            // }
            if (this.selected_attended != null) {
                obj['placement_drive_status'] = this.selected_attended.toString();
            }
            if (this.selected_call_status != null) {
                obj['call_status'] = this.selected_call_status.toString();
            }
            if (this.registration_date != null) {
                obj['month'] = moment(this.registration_date).format("MM");
                obj['year'] = moment(this.registration_date).format("YYYY")
            }
            if (this.enrollment_date != null) {
                obj['enrollment_month'] = moment(this.enrollment_date).format("MM");
                obj['enrollment_year'] = moment(this.enrollment_date).format("YYYY")
            }
            if (stats == 'registration_stats') {
                if (row == '' || row == null) {
                    obj.source = 'no_source';
                } else {
                    obj.source = row;
                }
            } else {
                if (row == '' || row == null) {
                    obj.city_classification = 'no_city';
                } else {
                    obj.city_classification = row;
                }
            }
            console.log("clicked data", obj);
            let url = `${constants.MILES_CRM_DEV}viewPlacemntDriveCandidate?page=${this.currentTablePage}`;
            axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                .then((response) => {
                    this.selected_stats = response.data.selected_stats;
                    this.tabulator_details = response.data.candidate_details.data
                    this.currentTablePage = response.data.candidate_details.current_page;
                    this.tablelinks = response.data.candidate_details.last_page;
                    this.city_calssification = response.data.city_calssification;
                    this.source = response.data.source;
                    console.log("table data", response.data);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error)
                    this.$vs.loading.close();
                });
        },
        showTreeBox() {
            console.log("clicked");
            return this.treeDataBDE;
        },
        generateKey(item) {
            return `${item}-${Math.random()}`;
        },
        updatePlacementdrive(payload){
            console.log('addMWB response', payload);
            let url = "https://mileswebinars.2x2.ninja/api/updateMwbIdForRegistration";
            let obj = {
                mwb_id : payload.mwb_data,
                email : payload.email
            }
            axios
                .post(url, obj, {
                    headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("updateMwbIdForRegistration", response.data);
                    this.getStats(this.selected_webinar)
                    this.getTabulatorDeatils(this.selected_filters.row, this.selected_filters.gm_id, this.selected_filters.val, this.selected_filters.stats)
                }).catch((error) => {
                    this.handleError(error);
                });
        },
    },
};
</script>

<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
    height: 38px;
}

.approval_search_container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100%;
    min-height: 394.69px;
}

.enrollment_count {
    background: #0044ba;
    border-radius: 15px 0px;
    width: 260.62px;
    height: 37.8px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1%;
    align-items: center;
}

.enrollment_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
}

.none_padding {
    padding: 0px;
}

.filter-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.enrollment-tree-drop#citys>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid rgba(46, 13, 146, 0.5);
    width: 90%;
    border-radius: 10px;
    margin-inline: 2%;
}

.enrollment-tree-drop#cc>.vs-con-dropdown {
    background: #fae1fe;
    border: 1px solid #de56f4;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#spoc>.vs-con-dropdown {
    background: rgba(188, 235, 250, 0.69);
    border: 1px solid #1fabd8;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#enrolled_spoc>.vs-con-dropdown {
    background: #ffcccc;
    border: 1px solid #faa2a2;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#sc>.vs-con-dropdown {
    background: #b4edff;
    border: 1px solid #13a0cd;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#gmFilter>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#fdback>.vs-con-dropdown {
    background: #f5e1ce;
    border: 1px solid #f1a964;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#Visitor>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
}

.whatsapp-tree-drop#level_drop>.vs-con-dropdown {
    background: #99eee3;
    border: 1px solid #14d685;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.whatsapp-tree-drop#macc_level_drop>.vs-con-dropdown {
    background: #edebf3;
    border: 1px solid #2e0d92;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;

}

.whatsapp-tree-drop#m_level_drop>.vs-con-dropdown {
    background: #c2ffc8;
    border: 1px solid #88dd91;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;

}

.enrollment-tree-drop#level>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
}

.enrollment-tree-drop#status>.vs-con-dropdown {
    background: #f5e1ce;
    border: 1px solid #f1a964;
    border-radius: 10px;
    width: 100%;
}

.enrollment-tree-drop#course>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #f38630;
    border-radius: 10px;
    width: 100%;
}

.enrollment-tree-drop#level_drop>.vs-con-dropdown {
    background: #99eee3;
    border: 1px solid #14d685;
    border-radius: 10px;
    width: 100%;
}

.whatsapp-tree-drop#attended>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #f19348;
    border-radius: 10px;
    width: 100%;
}

.whatsapp-tree-drop#call_status>.vs-con-dropdown {
    background: #f5e1ce;
    border: 1px solid #f19348;
    border-radius: 10px;
    width: 100%;
}

.enrollment-tree-drop#team>.vs-con-dropdown {
    background: #caecc5;
    border: 1px solid #a3d79b;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.enrollment-tree-drop#webinar>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #f09b58;
    border-radius: 10px;
    width: 100%;
}

.enrollment-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
}

.gm-approval_options_filter {
    min-height: 316.22px;
    margin: 2px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.gm-approval_options_filter .vx-card__collapsible-content .vx-card__body {
    padding: 5px 20px !important;
}

.alignment {
    text-align: initial;
}

.ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding-inline-start: 10px;
}

.ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
}

.a-iconns>.material-icons {
    margin-left: auto;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
}


.date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.enrollment-date-picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 44.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}

.aplproval_search_criteria {
    width: 100%;
    background: #ffffff;
    min-height: 175px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin-top: 2%;
}

.Criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.gm-option-side {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.criteria_chip {
    border-radius: 5px;
}

.criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
}

.criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
}

.gm-input-filter {
    width: 100% !important;
}

.gm-input-filter.vs-con-input .input-span-placeholder {
    font-size: 14px;
    margin-left: 1%;
}

.gm-input-filter>.vs-con-input>.vs-inputx {
    height: 40px;
    border: 1px solid #0044ba !important;
    border-radius: 15px;
}

.vs-dropdown--menu {
    width: 300px;
}

.desc-border-box3 {
    border: 1px solid #0044ba;
}

.approved_navbar .vs-con-items {
    display: contents;
}

.approved_navbar {
    background: #ffffff;
    border: 1px solid #0044ba;
    border-radius: 10px;
    z-index: 1000;
}

.approved_navbar li.vs-navbar--item a {
    padding: 3px 17px;
    margin-block: 7px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.approved_navbar .vs-navbar--item:hover {
    color: black !important;
}

.gm-stats-filter-card .vx-card__collapsible-content .vx-card__body {
    padding: 0px !important;
}

.gm-stats-filter-card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.desc-border-box2 p {
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #0044ba;
    border-radius: 0 0 15px 15px;
    width: 20%;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.fixTableHead {
    overflow-y: auto;
    max-height: 350px;
}

.fixTableHead thead th {
    position: sticky;
    top: 0;
}

.rwd-table {
    height: 100%;
    margin: 10px auto;
    background-color: white;
    /* above is decorative or flexible */
    position: relative;
    /* could be absolute or relative */
    padding-top: 30px;
    /* height of header */


    width: 98%;
    font-family: 'Montserrat';
    margin: 25px auto;
    border-collapse: collapse;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10),
        0px 10px 20px rgba(0, 0, 0, 0.05),
        0px 20px 20px rgba(0, 0, 0, 0.05),
        0px 30px 20px rgba(0, 0, 0, 0.05);

    tr {
        &:hover {
            background: #f4f4f4;

            td {
                color: #555;
            }
        }
    }

    th,
    td {
        color: #000;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        padding: 5px 14px;
        border-collapse: collapse;
    }

    th:first-child,
    td:first-child {
        border-left: 0px solid transparent !important;
    }

    th {
        background: #0044ba;
        color: #fff;
        font-size: 12px;

        &.last {
            border-right: none;
        }
    }

    tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }

    tr:last-child th:last-child {
        border-top-right-radius: 10px;
    }

    tr:first-child td:last-child {
        border-top-right-radius: 10px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }
}

.rwd-table {
    tr td:last-child {
        font-size: 12px;
    }
}

.date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

#stats_total {
    color: #0044BA;
}

.macc_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 21.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}
// .vs-dropdown--menu {
  //   width: 700px;
// }
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  white-space: nowrap;
  width: 240px;
  overflow: hidden;
  text-overflow: 'ellipsis';
}
</style>
