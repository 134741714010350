<template>
    <div>
        <vx-card class="tabulator_card">
            <vs-row>
                <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="card2 no-padd mb-8">
                    <p style="text-align:center">
                        <span v-if="city_calssification != null">GM Stats - {{ city_calssification }} - &nbsp;</span><span
                            v-else>Registration Stats - <span v-if="source == 'no_source'">No Source</span><span v-else>{{
                                source }}</span> - &nbsp;</span>{{ giveSelectedStat(selected_stats) }}
                    </p>
                </vs-col>
                <div ref="table3" id="example-table-theme"></div>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import EventBus from "../components/eventbus";
import moment from "moment";
export default {
    components: {
        Treeselect,
    },
    props: ["table_details", "selected_stats", "city_calssification", "source"],
    data() {
        return {
            selected_column: [],
        }
    },
    mounted() {
        console.log("clickk", this.$store.state.NetEnquiries);

        this.getEnrollmentDetails();
        for (let i = 0; i < this.table_details.length; i++) {
            const element = this.table_details[i];
            if (element.lead_created_at != null) {
                element.lead_created_at = moment(element.lead_created_at).format("DD-MMM-YYYY");
            }
            if (element.batch_start_date != null) {
                element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
            }
            if (element.enrollment_date != null) {
                element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
            }
            if (element.last_call !== null) {
                element.last_call = moment
                    .unix(element.last_call)
                    .format("DD-MMM-YYYY");
            }
            if (element.next_call !== null) {
                element.next_call = moment
                    .unix(element.next_call)
                    .format("DD-MMM-YYYY");
            }
            if (element.mhp_date !== null) {
                element.mhp_date = moment
                    .unix(element.mhp_date)
                    .format("DD-MMM-YYYY");
            }
            if (this.selected_stats == 'NE') {
                Object.assign(element, { call: "https://icon-library.com/images/round-phone-icon/round-phone-icon-0.jpg" });
                Object.assign(element, { add_lead: "https://icon-library.com/images/icon-edit/icon-edit-1.jpg" });

            } else {
                Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
            }
        }
    },
    watch: {
        table_details(val) {
            this.table_details = val;
            this.getEnrollmentDetails();
            for (let i = 0; i < this.table_details.length; i++) {
                const element = this.table_details[i];
                if (element.lead_created_at != null) {
                    element.lead_created_at = moment(element.lead_created_at).format("DD-MMM-YYYY");
                }
                if (element.batch_start_date != null) {
                    element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
                }
                if (element.enrollment_date != null) {
                    element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
                }
                if (element.last_call !== null) {
                    element.last_call = moment
                        .unix(element.last_call)
                        .format("DD-MMM-YYYY");
                }
                if (element.next_call !== null) {
                    element.next_call = moment
                        .unix(element.next_call)
                        .format("DD-MMM-YYYY");
                }
                if (element.mhp_date !== null) {
                    element.mhp_date = moment
                        .unix(element.mhp_date)
                        .format("DD-MMM-YYYY");
                }
                if (this.selected_stats == 'NE') {
                    Object.assign(element, { call: "https://icon-library.com/images/round-phone-icon/round-phone-icon-0.jpg" });
                    Object.assign(element, { add_lead: "https://icon-library.com/images/icon-edit/icon-edit-1.jpg" });
                } else {
                    Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
                }
            }
        },
    },
    methods: {
        giveSelectedStat(val) {
            console.log('giveSelectedStat', val);
            if (val == 'total') {
                return 'Total';
            }
            if (val == 'M1') {
                return 'M1';
            }
            if (val == 'M2') {
                return 'M2';
            }
            if (val == 'M3') {
                return 'M3';
            }
            if (val == 'M3+') {
                return 'M3+';
            }
            if (val == 'M4-') {
                return 'M4-';
            }
            if (val == 'M4') {
                return 'M4';
            }
            if (val == 'M5') {
                return 'M5';
            }
            if (val == 'M6') {
                return 'M6';
            }
            if (val == 'Blank') {
                return 'Blank';
            }
            if (val == 'U0--') {
                return 'U0--';
            }
            if (val == 'U0') {
                return 'U0';
            }
            if (val == 'U1') {
                return 'U1';
            }
            if (val == 'U1+') {
                return 'U1+';
            }
            if (val == 'U2') {
                return 'U2';
            }
            if (val == 'U3-') {
                return 'U3-';
            }
            if (val == 'U3') {
                return 'U3';
            }
            if (val == 'U3+') {
                return 'U3+';
            }
            if (val == 'U3++') {
                return 'U3++';
            }
            if (val == 'U4-') {
                return 'U4-';
            }
            if (val == 'U4') {
                return 'U4';
            }
            if (val == 'U5') {
                return 'U5';
            }
            if (val == 'U6-') {
                return 'U6-';
            }
            if (val == 'U6') {
                return 'U6';
            }
            if (val == 'U6+') {
                return 'U6+';
            }
            if (val == 'U7-') {
                return 'U7-';
            }
            if (val == 'U7') {
                return 'U7';
            }
            if (val == 'U8') {
                return 'U8';
            }
            if (val == 'U9') {
                return 'U9';
            }
            if (val == 'U10') {
                return 'U10';
            }
            if (val == 'NE') {
                return 'NE';
            }
            if (val == 'Others') {
                return 'Others';
            }
        },
        getEnrollmentDetails() {
            let columns = [];
            if (this.selected_stats != 'NE') {
                columns = [
                    {
                        title: "Can-ID",
                        field: "identity",
                        frozen: true,
                        sorter: "alphanum",
                    },
                    {
                        title: "Candidate Name",
                        field: "person_name",
                        frozen: true,
                    },
                    {
                        title: "Company",
                        field: "company",
                    },
                    {
                        title: "Experience",
                        field: "experience",
                    },
                    {
                        title: "Education",
                        field: "education_tags",
                    },
                    {
                        title: "M Level",
                        field: "level",
                    },
                    {
                        title: "U Level",
                        field: "iiml_level",
                    },
                    {
                        title: "Enrollment Date",
                        field: "enrollment_date",
                    },
                    {
                        title: "Course",
                        field: "courses",
                    },
                    {
                        title: "CM SPOC",
                        field: "spoc_name",
                    },
                    {
                        title: "SPOC City",
                        field: "spoc_city",
                    },
                    // {
                    //     title: "City",
                    //     field: "session_city",
                    // },
                    {
                        title: "GM",
                        field: "gm_name",
                    },
                    {
                        title: "SR SPOC",
                        field: "sr_name",
                    },
                    {
                        title: "Registerd SPOC",
                        field: "register_spoc",
                    },
                    {
                        title: "Comments",
                        field: "processing_comments",
                    },
                    {
                        title: "Lead Created Date",
                        field: "lead_created_at",
                    },
                    {
                        title: "Last Call",
                        field: "last_call",
                    },
                    {
                        title: "Next Call",
                        field: "next_call",
                    },
                    {
                        title: "FAM",
                        field: "fma_lma.first_source",
                    },
                    {
                        title: "LAM",
                        field: "fma_lma.last_source",
                    },
                    // {
                    //     title: "Registered Course",
                    //     field: "course",
                    // },
                    {
                        title: "MHP Date",
                        field: "mhp_date",
                    },
                    // {
                    //     title: "M Level",
                    //     field: "level",
                    // },
                    // {
                    //     title: "U Level",
                    //     field: "iiml_level",
                    // },
                    {
                        title: "About",
                        field: "info",
                        frozen: true,
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.getId,
                    },
                ]
            } else {
                columns = [
                    {
                        title: "Can-ID",
                        field: "identity",
                        frozen: true,
                        sorter: "alphanum",
                    },
                    {
                        title: "Candidate Name",
                        field: "person_name",
                        frozen: true,
                    },
                    {
                        title: "Company",
                        field: "company",
                    },
                    {
                        title: "Experience",
                        field: "experience",
                    },
                    {
                        title: "Education",
                        field: "education_tags",
                    },
                    {
                        title: "M Level",
                        field: "level",
                    },
                    {
                        title: "U Level",
                        field: "iiml_level",
                    },
                    {
                        title: "Enrollment Date",
                        field: "enrollment_date",
                    },
                    {
                        title: "Course",
                        field: "courses",
                    },
                    {
                        title: "CM SPOC",
                        field: "spoc_name",
                    },
                    {
                        title: "SPOC City",
                        field: "spoc_city",
                    },
                    // {
                    //     title: "City",
                    //     field: "session_city",
                    // },
                    {
                        title: "GM",
                        field: "gm_name",
                    },
                    {
                        title: "SR SPOC",
                        field: "sr_name",
                    },
                    {
                        title: "Registerd SPOC",
                        field: "register_spoc",
                    },
                    {
                        title: "Comments",
                        field: "processing_comments",
                    },
                    {
                        title: "Lead Created Date",
                        field: "lead_created_at",
                    },
                    {
                        title: "Last Call",
                        field: "last_call",
                    },
                    {
                        title: "Next Call",
                        field: "next_call",
                    },
                    {
                        title: "FAM",
                        field: "fma_lma.first_source",
                    },
                    {
                        title: "LAM",
                        field: "fma_lma.last_source",
                    },
                    // {
                    //     title: "Registered Course",
                    //     field: "course",
                    // },
                    {
                        title: "MHP Date",
                        field: "mhp_date",
                    },
                    // {
                    //     title: "M Level",
                    //     field: "level",
                    // },
                    // {
                    //     title: "U Level",
                    //     field: "iiml_level",
                    // },
                    {
                        title: "Action",
                        field: "call",
                        frozen: true,
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.calluntracked,
                    },
                    {
                        title: "",
                        field: "add_lead",
                        frozen: true,
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.openPopUp,
                    },
                ]
            }
            this.tabulator = new Tabulator(this.$refs.table3, {
                maxHeight: "80vh",
                // layout: "fitColumns",
                data: this.table_details,
                rowFormatter: (function (row) {
                    var evenColor = "#F5F6FA";
                    var oddColor = "#FFF";
                    row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
                }),
                columns: columns
            });
        },
        getId(e, cell) {
            console.log(e, cell);
            this.openBigPopup(cell._cell.row.data.mwb_id);
        },
        openPopUp(e, cell) {
            console.log(e, cell);
            let obj = cell._cell.row.data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            EventBus.$emit("open-netEnquiries-details-popup", obj);
        },
        async calluntracked(e, cell) {
            console.log(e, cell);
            let obj = cell._cell.row.data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            let response = await this.checkPermission();
            if (localStorage.getItem("role") !== "cm4") {
                if (
                    this.$route.path !== "/queue" &&
                    this.$route.path !== "/placementDrive" &&
                    this.$route.path !== "/logout" &&
                    this.$route.path !== "/UtilizationReport" &&
                    this.$route.path !== "/receipts"
                ) {
                    if (response === "no") {
                        this.$vs.notify({
                            title: "Permission denied",
                            text: "Please contact admin!!!",
                            color: "danger",
                            icon: "error",
                        });
                        return;
                    }
                }
            }
            let params = {};
            params.phone_number = cell._cell.row.data.phone;
            params.email = cell._cell.row.data.email;
            axios
                .get(constants.SERVER_API + "clickToCallUntracked", {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    // this.open = false;
                    // if (this.userdata.matched !== 1) {
                        EventBus.$emit("open-netEnquiries-details-popup", obj);
                    // }
                })
                .catch((error) => {
                    this.handleError(error);
                });

        },

    },
};
</script>

<style>
.tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator {
    background-color: #f2f2f2;
}

.tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
    text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
}

.vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: 0;
}

.card2 p {
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #6d7ae0;
    border-radius: 0 0 15px 15px;
    width: 52%;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}</style>
